// ==========================================================================
// LAYOUT / FORMS
// ==========================================================================
fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-weight: 600;
  margin-bottom: $small-spacing / 2;
  padding: 0;
}

label {
  display: inline-block;
  font-weight: 400;
  margin-bottom: $small-spacing * 0.3;
}

input,
select,
textarea {
  display: block;
  font-family: $base-font-family;
  font-size: $base-font-size * 1.25;
}

#{$all-text-inputs} {
  appearance: none;
  background-color: $base-background-color;
  border: $base-border;
  border-radius: $base-border-radius;
  box-shadow: $form-box-shadow;
  box-sizing: border-box;
  margin-bottom: 0;
  padding: ($base-spacing / 2) ($base-spacing / 2);
  transition: border-color $base-duration $base-timing;
  width: 100%;

  &:hover {
    border-color: shade($base-border-color, 20%);
  }

  &:focus {
    border-color: $action-color;
    box-shadow: $form-box-shadow-focus;
    outline: none;
  }

  &:disabled {
    background-color: shade($base-background-color, 5%);
    cursor: not-allowed;

    &:hover {
      border: $base-border;
    }
  }

  &::placeholder {
    color: tint($base-font-color, 20%);
  }

  @include grid-media($grid-md) {
    padding: ($base-spacing / 1.5) ($base-spacing / 2);
  }
}


textarea {
  resize: vertical;
}

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: $small-spacing / 2;
  width: 20px !important;
  &:checked + label {
    background: $ssc-bluegreen;
    box-shadow: $form-box-shadow;
    border-color: shade( $ssc-lightblue, 10% );
    color: $ssc-white;
  }
}

[type="file"] {
  margin-bottom: $small-spacing;
  width: 100%;
}

select {
  margin-bottom: $small-spacing;
  width: 100%;
}

input:not([type=submit]):not([type=file]),
textarea{
  width: 100%;
  background: rgba(255,255,255,0.6);

  &::placeholder{
    color: darken( $medium-gray, 10% );
  }
}


//
// Special Select
// --------------------------------------------------------------------------
.select {
  display: block;
  font-size: $base-font-size;
  font-weight: 700;
  color: $dark-gray;
  line-height: 1.3;
  padding: .6em 1.4em .5em .8em;
  width: 100%;
  max-width: 100%; 
  box-sizing: border-box;
  margin: 0;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%,#f2f2f2 100%);
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;

  &::-ms-expand {
    display: none;
  }
  &:hover {
    border-color: #888;
  }
  &:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222; 
    outline: none;
  }

  option {
    font-weight:normal;
  }
}


//
// Parsley Validation
// --------------------------------------------------------------------------
input,
select,
textarea {
  &.parsley-success {
    color: #468847;
    background-color: #DFF0D8;
    border: 1px solid #D6E9C6;
  }

  &.parsley-error {
    color: #B94A48;
    border-color: $action-color !important;
    box-shadow: $form-box-shadow, 0 0 10px rgba($action-color, 0.4), 0 0 5px rgba($action-color, 0.4) inset;
  }
}

.parsley-errors-list {
  margin: 2px 0 3px;
  padding: 5px;
  list-style-type: none;
  font-size: 0.8em;
  line-height: 0.9em;
  opacity: 0;
  background: tint( $action-color, 80%);
  color: shade( $action-color, 40% );

  transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -moz-transition: all .3s ease-in;
  -webkit-transition: all .3s ease-in;

  li { margin: 0; }
}

.parsley-errors-list.filled {
  opacity: 1;
}


//
// Basic Structure
// --------------------------------------------------------------------------
.form-group {
  @include clearfix();
  padding: 0;
  margin-bottom: $base-spacing * 0.8;

  div { }

  span {
    text-align: left;
    margin-bottom: 0;
  }

  label {
    font-weight: 700;
    line-height: 1.1em;
    margin-bottom: $small-spacing * 0.75;

    span {
      font-size: 90%;
      font-style: italic !important;
      font-weight: 400;
      padding-left: 0.5em;
      color: #999;
    }
  }

    // label {
    //   font-weight: 400;
    //   margin-bottom: 0;

    //   em {
    //     
    //   }
    // }


  .desc {
    font-size: $base-font-size * 0.7;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: 700;
  }

 .btn {
    font-size: $base-font-size * 1.5;
  }

  @include grid-media($grid-md){
    .btn {
      font-size: $base-font-size * 1.8;
    }

}

.form-label--hidden {
    @include hide-visually();
}
  //
  // Specific Sections
  // --------------------------------------------------------------------------

   &--short {
      input {
        width: 120px !important;
      }
   }
  // Yes/No Radio buttons
  &--yesno {
    input {
      opacity: 0;
      position: absolute;
      z-index: -10;
      top: 0;

      &:focus + label.btn {
        background: $ssc-bluegreen;
      }
    }

    div {
      display: flex;
    }

    span {
      margin-right: 1em;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }

    .btn {
      font-size: $base-font-size * 1;
      border: $base-border;
      box-shadow: none;
      color: $dark-gray;
      font-weight: 700;
      padding: 0.6em 2em;
      background-color: #fff;
      background-image: linear-gradient(to bottom, #ffffff 0%,#f2f2f2 100%);
      background-repeat:repeat;
      background-position: 0 0;
      background-size:  100%;
      transition: all $base-duration $base-timing;

      &:hover {
        box-shadow: none;
       // background-color: $debt-green;
      //  background-image: linear-gradient(to bottom, $debt-green 0%, shade( $debt-green, 5%) 100%);
       // border-color: shade( $debt-green, 10% );
      }

      &--selected {
        background: $ssc-bluegreen;
        box-shadow: $form-box-shadow;
        border-color: shade( $ssc-lightblue, 10% );
        color: $ssc-white;
      }

    }
  }


  // Checkboxes
  &--multi {
   // margin-bottom: $base-spacing;

    span{
      @include grid-column(12);
      display: flex;
      align-items: center;

       label {
        flex-grow: 2
      }
    }
    input {
      width: auto !important;
      zoom: 1.5;
      transform: scale(1.5);
      transform-origin: 0 0;
      margin: 0;
      margin-right: 1em;
      flex-grow: 0;
    }
   

  }


  // First/Last Name
  &--name {

    div {
      display: flex;

    }

    span {
      flex-grow: 1;
      margin-right: 1em;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &--date {
    margin-bottom: 0 !important;

    div {
      display: flex;
    }

    span {
      flex-grow: 1;
      margin-right: 1em;
      margin-bottom: 0;

      &:last-child {
        flex-grow: 2;
        margin-right: 0;
      }
    }
  }


  // Button Holder
  &--btn {
   // padding-top: $base-spacing;
    text-align: center;

    .btn {

    }
  }
  
}




label {
  font-size: $base-font-size;
  font-weight: 400;
  color: $dark-gray;
  display: block;
  padding-left: 2px;

  @include grid-media($grid-lg){
    font-size: $base-font-size * 1.1;
  }

  &.desc {
    padding-left: $small-spacing 2px;
    padding-top: $small-spacing / 2;
    color: #999;
  }

  .field & {
    font-size: 75%;
    font-weight: 700;
    padding-left: $small-spacing;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    color: $medium-gray;
  }

  &.btn {
    color: #fff;
     padding-left: $base-spacing * 1.5;
    padding-right: $base-spacing * 1.5;
  }

  .legal & {
    text-transform: none;
    letter-spacing: 0;
    font-size: 90%;
    font-weight: 400;
    padding-left: 0;
  }


}

// Button Holder
.btn-group {
  text-align: right;
  margin-bottom: $base-spacing;

}

.form-group--yesno span:nth-child(odd) {
  text-align: right;
}

.form-group--yesno span {
  width: calc(50% - 15px);
  float: left;
  margin-left: 10px;
  text-align: left;
}
 
.form-group--name span {
  width: 100%;
  float: left;
}

.form-group--yesno input {
  opacity: 0;
  position: absolute;
  z-index: -10;
  top: 0;
}

.offer-form {
  text-align: center;
}