#{$all-buttons},
.btn {
  appearance: none;
  background-color: shade($action-color, 10%);
  border: 0;
  border-radius: 100em;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: $base-font-family;
  font-size: $base-font-size;
  -webkit-font-smoothing: antialiased;
  font-weight: 800;
  line-height: 1;
  padding: ($small-spacing * 1.25) ($base-spacing * 2);
  text-align: center;
  text-decoration: none;
  transition: all $base-duration $base-timing;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  box-shadow: 0 -2px 0 rgba(0,0,0,0.2) inset, 0 4px 6px rgba(0,0,0,0.1);


  &:hover,
  &:focus {
    background: $ssc-green;
    color: #fff;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      background-color: $ssc-green;
    }
  }
}

.btn {
padding: ($base-spacing * .65) ($base-spacing * 1.5);

}

// Button Alternate Sizes
.btn--micro {
  padding: $small-spacing $base-spacing;
  font-size: $base-font-size * .9;
  letter-spacing: 0.1em;
}

.btn--puffy {
    padding: ($small-spacing * 1) ($base-spacing * 1.75);
    text-transform: none;
    letter-spacing: 0;

}

// Button Alternate Styles
.btn--primary {
  background-color: $action-color;

  &:hover {
    background-color: shade($action-color, 10%);
  }
}

.btn--secondary {
  background-color: $action-color-alt;
 // color: $debt-blue;

  &:hover {
  background-color: shade( $action-color-alt, 10%);
  }
}

.btn--alternate {
  background-color: shade($action-color, 10%);

  &:hover {
  background-color: shade($action-color, 10%);

  }
}

.btn--alternate-secondary {
  background-color: shade($action-color, 10%);

  &:hover {
    background-color: shade($action-color, 10%);
  //  box-shadow: inset 0 0 0 5px shade( $diablo-gray, 15% );
  }
}
