// ==========================================================================
// LAYOUT / SITE
// ==========================================================================


//
// Globals
// --------------------------------------------------------------------------

@-webkit-viewport {
width : device-width; }

@-moz-viewport {
width : device-width; }

@-ms-viewport {
width : device-width; }

@-o-viewport {
width : device-width; }

@viewport {
width : device-width; }


html {
	background: $ssc-darkblue;
	-webkit-overflow-scrolling : touch;
	-webkit-tap-highlight-color : lighten( $base-font-color, 75% );
	-webkit-text-size-adjust : 100%;
	-ms-text-size-adjust : 100%;
}

body {
	background-color: transparent;
	font-family : $base-font-family;
	line-height : $base-line-height;
	color : $base-font-color;
	-webkit-font-smoothing: antialiased;
}

::-moz-selection {
	background-color : lighten( $action-color, 10% );
	//color : $base-font-color;
	color : #fff;
	text-shadow : none; }

::selection {
	background-color : lighten( $action-color, 10% );
	//color : $base-font-color;
	color : #fff;
	text-shadow : none; }

.browserupgrade {
	background: #333;
	padding: $base-spacing;
	text-align: center;
	color: #ccc;

	em {
		font-style: italic;
		color: #fff;
	}

	a {
		color: $action-color;
		font-weight: 700;
		text-decoration: underline;
	}
}
//
// Content Wrappers
// --------------------------------------------------------------------------
.wrapper,
.wrapper--full {
	padding-left: 2.5%;
	padding-right: 2.5%;
	width: auto;
	position: relative;
	@include grid-container;

	@include grid-media($grid-sm){
		padding-left: 2%;
		padding-right: 2%;
	}
}

.wrapper {
	@include grid-media($grid-lg){
		padding-left: 0;
		padding-right: 0;
		width: 960px;
		margin-left: auto;
		margin-right: auto;
	}

	@include grid-media($grid-xlg){
		width: 1100px;
	}
}

.banner-wrapper {
	@include grid-container;
	width: auto;
	position: relative;
	z-index: 5;

	@include grid-media($grid-sm) {
		padding: $base-spacing;
	}


	@include grid-media($grid-lg) {
		padding-left: 0;
		padding-right: 0;
		width: 960px;
		margin-left: auto;
		margin-right: auto;
	}

	@include grid-media($grid-xlg){
		width: 1170px;
	}
}





//
// Site Specifics
// --------------------------------------------------------------------------
.page-banner {
	background: $ssc-darkblue url("../img/banner-lg.jpg") no-repeat top center;
	background-size: cover;
}

#masthead {
	@include grid-container;
	padding-top: $small-spacing / 2;
	padding-bottom: $small-spacing;
	background: transparent;

	.logo {
		width: 180px;
		margin-left: auto;
		margin-right: auto;

		.fill-1 {
			fill: $ssc-white;
		}
    	.fill-2 {
    		fill: $ssc-bluegreen;
    	}
	}

	.logo-text {
		@include hide-visually();
	}

	@include grid-media($grid-sm){
		border-bottom: 1px solid darken($ssc-darkblue, 5%);

		.logo {
			margin: 0;
		}
	}
	@include grid-media($grid-md) {
		.logo {
			width: 160px;
		}
	}
 }

#primary {
	@include grid-container;
	//background-color: $debt-blue;
	padding-top: $base-spacing;
	padding-bottom: $base-spacing * 2;

	@include grid-media($grid-sm){
	//	background: $debt-blue url('../../img/bg-cards.jpg') no-repeat center bottom;
		background-size: cover;
	}
}


//
// Primary Area Structure
// --------------------------------------------------------------------------
.primary-copy,
.form-wrapper {
	@include grid-column(12);
}
.primary-copy{
	@include grid-media($grid-md) {
		@include grid-column(5);
	}
}
.form-wrapper {
	@include grid-media($grid-md) {
		@include grid-column(6);
		@include grid-push(1);
	}
}


.path-container {
	background: #fff;
	margin-top: $base-spacing;
	margin-bottom: $base-spacing;
	border-radius: 4px;
	padding: $base-spacing;
	@include box-shadow(0, 3px, 10px, 0, rgba(0,0,0,0.05) ); 
}
//
// Main Copy 
// --------------------------------------------------------------------------
.primary-copy {
	padding-top: $base-spacing;
	text-align: center;

	@include grid-media($grid-md) {
		text-align: left;
	}

	div {
		display: none;
		
		@include grid-media($grid-md) {
			display: block;
		}
	}

	h2 {
		font-size: $base-font-size * 1.5;
		font-weight: 800;
		color: $ssc-white;
		line-height: 1em;

		@include grid-media($grid-sm) {
			font-size: $base-font-size * 1.75;
		}
		@include grid-media($grid-md) {
			font-size: $base-font-size * 2.5;
			letter-spacing: -0.03em;
		}
	}

	h4 {
		color: $ssc-green;
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: $small-spacing;
	}

	ul li {
		font-size: $base-font-size * 1.15;
		color: lighten( $ssc-green, 10% );
		margin-bottom: $small-spacing;
		position: relative;
		padding-left: 1.5em;
		line-height: $base-spacing * 0.85;

		&::before {
			content: '';
			position: absolute;
			left: 0.35em;
			top: 0.5em;
			width: 0.5em;
			height: 0.5em;
			background: darken( $ssc-bluegreen, 25% );
			border-radius: 100em;
		}

		@include grid-media($grid-md) {
			font-size: $base-font-size * 1.35;
		}
	}

	@include grid-media($grid-sm) { 
		padding-top: $base-spacing * 3;
	}
}

//
// Form
// --------------------------------------------------------------------------
.form-wrapper {
	background: $ssc-bluegreen;
	border: 10px solid darken($ssc-bluegreen, 8% );
	@include box-shadow( 0, 5px, 12px, 0, rgba(0,0,0,0.2) );
	border-radius: 16px;
	padding: 0.5em;

	.form-header {
		text-align: center;
		padding: $small-spacing $small-spacing 0;

		h2 {
			color: $ssc-white;
			font-weight: 800;
		}
	}

	form {
		background: #f2f2f2;
		@include box-shadow( 0, 1px, 4px, 0, rgba(0,0,0,0.2) );
		border-radius: 3px;
		padding: 1em;
	}

	fieldset {

	}

	.legal {
		font-size: $base-font-size * 0.7;
	}
	
	// @include grid-media($grid-sm) { 
	// 	margin-top: -4em;
	// 	margin-bottom: -5em;
	// }
}



//
// Secondary Copy
// --------------------------------------------------------------------------
#secondary {
	padding-top: $base-spacing;
	padding-bottom: $base-spacing;
	background: $ssc-white;

	h3 {
		color: $ssc-blue;
		font-weight: 700;
		padding-top: $small-spacing;
		font-size: $base-font-size * 1.5;
		margin-bottom: $small-spacing / 2;
	}


	@include grid-media($grid-sm) {
		padding-top: $base-spacing * 2;
		padding-bottom: $base-spacing * 2;
		h3 {
			font-size: $base-font-size * 1.75;
		}
		p {
			font-size: $base-font-size * 1.15;
		}
	}


	@include grid-media($grid-md) {
		padding-top: $base-spacing * 4;
		padding-bottom: $base-spacing * 3;

		h3 {
			font-size: $base-font-size * 2.25;
			letter-spacing: -0.03em;
		}
	}
	@include grid-media($grid-lg) {
		
	}
}

.features {
	div {
		text-align: center;
		margin-bottom: $base-spacing;
	}

	.icon {
		width: 100px;
		height: 100px;
		border: 5px solid lighten($ssc-green, 40% );
		border-radius: 100px;
		display: block;
		margin: 0 auto;
	}


	@include grid-media($grid-sm) {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-end;

		div {
			width: 50%;
			padding: 1em;
		}

		.icon {
			width: 200px;
			height: 200px;
		}
	}
}

//
// Footer
// --------------------------------------------------------------------------

#site-footer {
	background: $ssc-blue;
	color: #f2f2f2;
	padding-top: $base-spacing;
	padding-bottom: $base-spacing;

	a {
		color: #f2f2f2;
		&:hover {
			color: #fff;
		}
	}

	.logo {
		@include grid-column(12);
		.fill-1 { fill: $ssc-white; }
		.fill-2 { fill: $ssc-bluegreen; }

		svg {
			width: 120px;
			margin-left: auto;
			margin-right: auto;
			display: block;
		}
	}

	.footer-content {
		@include grid-column(12);
		padding-top: $base-spacing;
	}

	@include grid-media($grid-md) {
		.logo {
			@include grid-column(2);
			margin-left: 0;
			margin-right: 0;
			display: inline-block;
		}
		.footer-content {
			@include grid-column(10);
		}
		.legalese {
			width: 90%;
		}
	}
	
	.copyright, nav {
		@include clearfix();
		font-size: $base-font-size * 0.9;
		font-weight: 700;
		float: left;
		margin-bottom: $base-spacing * 0.5;
		margin-right: 1em;
	}

	.legalese {
		font-size: $base-font-size * 0.8;
		clear: left;
	}

	// Offer Path page variant
	&.minimal-footer {
		background: transparent;
		color: #999;

		a {
			color: #999;
		}
	}
}

.hidden {
	display: none;
}